import { Environment } from "src/app/config/environment.type";
import { GlobalRole } from "src/app/enumerations/GlobalRole";

export const environment: Environment = {
  production: false,

  baseApiUrl: 'https://api.cloudlab-dev.tecan.com/api',
  signalrHubUrl: 'https://tecan-app-cloudlab-dev-backend.azurewebsites.net/hubs/room',

  auth0: {
    domain: "login-dev.tecan.com",
    clientId: "JJsXx9EWD95fjD9Q47WsfVNSN3EuM8CZ",
    audience: "https://api.cloudlab-dev.tecan.com",
    rolesClaim: "https://tecan.com/roles",
    roleMappings: {
      "Admin": GlobalRole.Admin,
      "RoomAdmin": GlobalRole.RoomAdmin,
      "ProjectAdmin": GlobalRole.ProjectAdmin,
      "TemplateAdmin": GlobalRole.TemplateAdmin,
      "Host": GlobalRole.Host
    }
  },

  costEstimate: {
    hourlyBaseCost: {
      'EUR': 20,
      'USD': 20
    }
  }
};
